import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

class Logo extends Component {

  render() {
      return(
        <div className="logo">
          <Link to = { "./" } ><img src={ "/img/logo.png" } alt="logo"></img></Link>
        </div>
      );
  }
}

export default Logo;
