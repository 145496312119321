import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";

export default class CursoSelectRol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedsolviacanal: [],
            selectedsolviarol: [],
            selectedstores: []
        }
    }

    handleChangeCanal = async selectedOption => {
        this.props.handleChangeCanal(selectedOption);
        this.setState({ selectedsolviacanal: selectedOption });
    };

    handleChangeRol = async selectedOption => {
        this.props.handleChangeRol(selectedOption);
        await this.setState({ selectedsolviarol: selectedOption });
    };

    handleChangeStore = async selectedOption => {
        this.props.handleChangeStore(selectedOption);
        await this.setState({ selectedstores: selectedOption });
    };

    render() {
        return (
            <Row style={{ marginTop: "25px", paddingRight: "10px", paddingLeft: "10px" }}>
                <Col>
                    <Select
                        isMulti
                        options={this.props.data.solviarols}
                        className="form-control rounded-select rounded_cont shadow"
                        onChange={this.handleChangeRol}
                        value={this.state.selectedsolviarol}
                        placeholder={"Rol"}
                    />
                </Col>
                <Col>
                    <Select
                        isMulti
                        options={this.props.data.solviacanals}
                        className="form-control rounded-select rounded_cont shadow"
                        onChange={this.handleChangeCanal}
                        value={this.state.selectedsolviacanal}
                        placeholder={"Canal"}
                    />
                </Col>
                <Col>
                    <Select
                        isMulti
                        options={this.props.data.stores}
                        className="form-control rounded-select rounded_cont shadow"
                        onChange={this.handleChangeStore}
                        value={this.state.selectedstores}
                        placeholder={"Store"}
                    />
                </Col>
            </Row>
        );
    }
}