import React, { Component } from "react";
import { Form, Button, Modal, ModalBody, Row, Col } from "reactstrap";
import ReactTable from "react-table";
import Loading from "../../components/Loading";
import request from "../../utils/request";
import auth from "../../utils/auth";
import "./styles.css";
import Select from "react-select";
import Cursos from "../Cursos";
import constantes from "../../utils/constantes";
import Moment from "react-moment";
import DateTimePicker from "react-datetime-picker";
import TablaParticipantes from "../../components/TablaParticipantes";
import _ from "lodash";
import LoadUser from "../../components/LoadUser";
var moment = require("moment");

export default class ListaCursos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idAvatar: "",
      idEditando: "",
      cursos: [],
      users: [],
      usuariosSelect: [],
      cursoSelected: [],
      solviacanals: [],
      solviarols: [],
      selectedsolviarol: "",
      selectedsolviacanal: "",
      selectedstores: "",
      cursoNombre: "",
      cursoIcon: "",
      cursoGroup: "",
      cursoOrder: "",
      alertAsignar: false,
      modal: false,
      modalGaleria: false,
      modalAsignar: false,
      modalUsuarios: false,
      isEditing: false,
      programada: false,
      entradaSemana: [],
      entradas: [],
      newDescription: "",
      newAvatar: "",
      numeroSemana: 1,
      entradasOrdenadas: [],
      userTokenSelected: "",
      idExFinal: [],
      selectedUsersId: [],
      selectBloque: [],
      selectedReto: [],
      retos: [],
      retosElectos: [],
      retosElectosId: [],
      retosPrevios: [],
      entradasCurso: [],
      usersAsignados: [],
      estadoUsuarios: [],
      stateUsers: [],
      avanceC: [],
      photo: "/img/userplaceholder.jpg",
      loading: false,
      start: 0,
      limit: 8,
      activePage: 1,
      totalCount: 0,
      init_date: new Date(),
      end_date: new Date()
    };

    this.toggle = this.toggle.bind(this);
    this.toggleGaleria = this.toggleGaleria.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.editcurso = this.editcurso.bind(this);
    this.filtracursos = this.filtracursos.bind(this);
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const graphC =
      "/graphql?query=%0A%7B%20%20cursos%7B%20%20%20%20_id%20%20%20%20nombre%20%20%20%20entrada%7B%20%20%20%20%20%20_id%20%20%20%20%7D%20%20%20%20%20%20%7D%20%20stores%7B%20%20%20%20_id%20%20%20%20name%20%20%7D%20solviarols%7B%20%20%20%20_id%20%20%20%20name%20%20%7D%20%20solviacanals%7B%20%20%20%20_id%20%20%20%20name%20%20%7D%7D%0A";
    const token = auth.getToken();
    await fetch(constantes("URL_API") + graphC, {
      method: "GET",
      body: JSON.stringify(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
      .then(data => data.json())
      .then(response => {
        this.setState({
          cursos: response.data.cursos,
          cursos2: response.data.cursos,
          solviacanals: response.data.solviacanals.map(solviacanal => {
            return {
              value: solviacanal._id,
              label: solviacanal.name
            };
          }),
          solviarols: response.data.solviarols.map(solviarol => {
            return {
              value: solviarol._id,
              label: solviarol.name
            };
          }),
          stores: response.data.stores.map(store => {
            return {
              value: store._id,
              label: store.name
            };
          })
        });
      })
      .catch(err => console.log(err));

    this.setState({ loading: false });
  };

  limpiezaEstados = () => {
    this.setState({
      idAvatar: "",
      idEditando: "",
      cursoNombre: "",
      cursoIcon: "",
      cursoGroup: "",
      cursoOrder: "",
      modal: false,
      modalGaleria: false,
      modalAsignar: false,
      isEditing: false,
      modalUsuarios: false,
      alertAsignar: false,
      newDescription: "",
      newAvatar: "",
      userTokenSelected: "",
      selectBloque: [],
      selectedReto: [],
      selectedUser: [],
      retos: [],
      numeroSemana: 1,
      retosElectos: [],
      retosElectosId: [],
      estadoUsuarios: [],
      usersAsignados: [],
      selectedUsersId: [],
      entradasOrdenadas: [],
      idExFinal: [],
      photo: "/img/userplaceholder.jpg"
    });
  };
  quitaReto(reto) {
    let array = this.state.retosElectos.filter(function (el) {
      return el.id !== reto.id;
    });
    let idarray = this.state.retosElectosId.filter(function (el) {
      return el !== reto.id;
    });
    this.setState({
      retosElectos: array,
      retosElectosId: idarray
    });
  }

  filtracursos(value) {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.cursos2.filter(array =>
        array.nombre.includes(value.target.value)
      );
      this.setState({ cursos: dataFiltro });
    } else {
      this.setState({ cursos: this.state.cursos2 });
    }
  }

  getData(bol) {
    if (bol) {
      request(constantes("URL_API") + "/retos", { method: "GET" }).then(
        count => {
          this.setState({
            totalCount: count.length
          });
        }
      );
    }
    var consulta =
      "/retos/?_limit=" +
      this.state.limit +
      "&_start=" +
      this.state.start +
      "&_sort=name:ASC";

    request(constantes("URL_API") + consulta, { method: "GET" }).then(retos => {
      this.setState({
        retos: retos
      });
    });
    request(constantes("URL_API") + "/retos/", { method: "GET" }).then(
      retos => {
        this.setState({
          retosAll: retos
        });
      }
    );
  }

  seleccionaReto(reto) {
    let selectedReto = [];
    selectedReto.push(reto.id);
    this.setState({
      selectedReto: selectedReto,
      selectedRetoId: selectedReto[0]._id
    });
  }

  actualizacursos(id) {
    const cursosMod = [...this.state.cursos]; //copia el estado
    cursosMod.find(e => e._id === id).status = !cursosMod.find(
      e => e._id === id
    ).status;
    this.setState({ cursos: cursosMod });
  }
  toggleGaleria() {
    this.getData();
    this.setState({
      modalGaleria: !this.state.modalGaleria
    });
  }
  toggle(id = "") {
    //si está abierto lo cerramos
    if (this.state.modal === true) {
      this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal
      });
    } else {
      this.setState({ loading: true });
      //compruebo que tenga ID válido
      if (id !== "") {
        //Si tiene ID, cargamos la data del ID y abrimos.
        this.setState({ loading: true });
        request(constantes("URL_API") + "/cursos/" + id, {
          method: "GET"
        }).then(curso =>
          this.setState({
            editcursoData: curso,
            cursoNombre: curso.nombre,
            cursoDesc: curso.descripcion,
            cursoIcon: curso.imagen,
            cursoCanal: curso.canal,
            cursoRol: curso.rol,
            cursoCert: curso.certificado,
            cursoEntradas: curso.entrada,
            idEditando: id,
            isEditing: true,
            modal: !this.state.modal,
            loading: false
          })
        );
      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modal: !this.state.modal,
          loading: false
        });
      }
    }
  }

  cleanModal = () => {
    this.setState({
      selectedstores: [],
      selectedsolviacanal: [],
      selectedsolviarol: [],
      selectedusers: [],
      estadoUsuarios: [],
      usuariosSelect: [],
      users: [],
      init_date: new Date(),
      end_date: new Date()
    });
  };
  duplicaCurso = (id = "") => {
    //compruebo que tenga ID válido
    if (id !== "") {
      //Si tiene ID, cargamos la data del ID y abrimos.
      //this.setState({ loading: true });
      request(constantes("URL_API") + "/cursos/" + id, {
        method: "GET"
      }).then(curso => {
        let body = {
          area_admin: curso.area_admin,
          certificado: curso.certificado,
          descripcion: curso.descripcion,
          entrada: curso.entrada,
          exam: curso.exam,
          imagen: curso.imagen,
          nombre: "Copia de " + curso.nombre,
          obligatorias: curso.obligatorias,
          porcentaje_total: curso.porcentaje_total
        };
        //console.log(body);
        const uriCursos = "/cursos";
        const method = "POST";
        const token = auth.getToken();
        fetch(constantes("URL_API") + uriCursos, {
          method,
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(data => data.json())
          .then(async response => {
            this.componentDidMount();
            if (response.statusCode === 400) throw new Error(response.message);
          })
          .catch(err => console.log("error:" + err));
      });
    }
  };

  usuariosSelect = users => {
    this.setState(users);
  };

  loading = loading => {
    this.setState({ loading });
  };

  toggleShowUsers = id => {
    this.cleanModal();
    //si está abierto lo cerramos
    if (this.state.modalUsuarios === true) {
      this.setState({
        modalUsuarios: !this.state.modalUsuarios
      });
    } else {
      //compruebo que tenga ID válido
      if (id !== "") {
        this.limpiezaEstados();
        //Si tiene ID, cargamos la data del ID y abrimos.
        this.setState({ loading: true });
        const uriC = "/avancecursos?cursos=" + id;
        const token = auth.getToken();
        fetch(constantes("URL_API") + uriC, {
          method: "GET",
          body: JSON.stringify(),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(data => data.json())
          .then(response => {
            let avanceList = response;
            avanceList.forEach(av => {
              this.setState({
                usersAsignados: [...this.state.usersAsignados, av.user],

                stateUsers: [...this.state.stateUsers, av.estao],
                modalUsuarios: !this.state.modalUsuarios
              });
            });
            let listaUsers = [];
            this.state.usersAsignados.forEach((lista, index) => {
              listaUsers.push({
                user: lista.username,
                state: this.state.stateUsers[index]
              });
            });
            this.setState({
              estadoUsuarios: [...this.state.estadoUsuarios, ...listaUsers],
              loading: false
            });
          })
          .catch(err => console.log(err));
      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modalUsuarios: !this.state.modalUsuarios
        });
      }
    }
  };

  toggleAsignar = id => {
    this.cleanModal();
    //si está abierto lo cerramos
    if (this.state.modalAsignar === true) {
      this.limpiezaEstados();
      this.setState({
        modalAsignar: !this.state.modalAsignar
      });
    } else {
      //compruebo que tenga ID válido
      if (id !== "") {
        //Si tiene ID, cargamos la data del ID y abrimos.
        this.setState({ loading: true });
        const uriC =
          "/graphql?query=%7B%20%20%0D%0A%20%20curso(id%3A%22" +
          id +
          "%22)%7B%0D%0A%20%20%20%20_id%0D%0A%20%20%20%20nombre%0D%0A%20%20%20%20entrada%7B%0D%0A%20%20%20%20%20%20_id%0D%0A%20%20%20%20%20%20title%0D%0A%20%20%20%20%7D%0D%0A%20%20%20%20exam%7B%0D%0A%20%20%20%20%20%20_id%0D%0A%20%20%20%20%7D%0D%0A%20%20%20%20obligatorias%0D%0A%20%20%20%20solviarols%7B%0D%0A%20%20%20%20%20%20_id%0D%0A%20%20%20%20%20%20name%0D%0A%20%20%20%20%7D%0D%0A%20%20%20%20solviacanals%7B%0D%0A%20%20%20%20%20%20_id%0D%0A%20%20%20%20%20%20name%0D%0A%20%20%20%20%7D%0D%0A%20%20%20%20stores%7B%0D%0A%20%20%20%20%20%20_id%0D%0A%20%20%20%20%20%20name%0D%0A%20%20%20%20%7D%0D%0A%20%20%7D%0D%0A%7D%0D%0A";
        // "/graphql?query=%7B%20%20curso(id%3A%22" +
        // id +
        // "%22)%7B%20%20%20%20_id%20%20%20%20nombre%20%20%20%20entrada%7B%20%20%20%20%20%20_id%20%20%20%20%20%20title%20%20%20%20%7D%20%20%20%20exam%7B%20%20%20%20%20%20_id%20%20%20%20%7D%20%20%20%20obligatorias%20%20%7D%7D";
        const token = auth.getToken();
        fetch(constantes("URL_API") + uriC, {
          method: "GET",
          body: JSON.stringify(),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(data => data.json())
          .then(response => {
            this.setState({
              cursoSelected: response.data.curso,
              cursoNombre: response.data.curso.nombre,
              modalAsignar: !this.state.modalAsignar,
              cursesolviacanals: response.data.curso.solviacanals.map(
                solviacanal => {
                  return {
                    value: solviacanal._id,
                    label: solviacanal.name
                  };
                }
              ),
              cursesolviarols: response.data.curso.solviarols.map(
                solviacanal => {
                  return {
                    value: solviacanal._id,
                    label: solviacanal.name
                  };
                }
              ),
              cursestores: response.data.curso.stores.map(solviacanal => {
                return {
                  value: solviacanal._id,
                  label: solviacanal.name
                };
              }),
              loading: false,
              programada: true,
              entradas: response.data.curso.entrada.map(ent => {
                return {
                  value: ent._id,
                  label: ent.title
                };
              })
            });
          })
          .catch(err => console.log(err));
      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modalAsignar: !this.state.modalAsignar
        });
      }
    }
  };

  gestionaBloque = id => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      //Añadir al array
      array.push(id);
    }
    this.setState({ selectBloque: array });
  };

  editcurso(id) {
    let body = {
      name: this.state.cursoNombre,
      icon: this.state.cursoIcon,
      group: this.state.cursoGroup,
      order: this.state.cursoOrder,
      retos: this.state.retosElectosId
    };
    request(constantes("URL_API") + "/cursos/" + id, {
      method: "PUT",
      body: body
    })
      .then(response => {
        this.handlechangeAvatar("curso", response._id);
        this.limpiezaEstados();
        this.componentDidMount();
      })
      .catch(err => console.log(err));
  }

  deletecurso = async id => {
    this.setState({
      loading: true
    });
    await request(constantes("URL_API") + "/cursos/" + id, { method: "DELETE" })
      .then(response => this.componentDidMount())
      .catch(err => console.log(err));

    this.limpiezaEstados();
    await this.componentDidMount();
    this.setState({
      loading: false
    });
  };

  closeModal = () => {
    this.setState({
      modal: false
    });
  };

  handleChangeUsers = selectedOption => {
    this.setState({
      usuariosSelect: selectedOption
    });
  };

  handleChangeEntradaSemana = async selectedOption => {
    await this.setState({
      entradaSemana: selectedOption
    });
  };

  addSemana = async () => {
    let array = this.state.entradas.map(x => {
      return x.value;
    });
    let arrayEntradas = [];
    let arrayFinal = [];

    this.state.entradaSemana.forEach(ent => {
      arrayEntradas.push({
        _id: ent.value,
        name: ent.label,
        semana: this.state.numeroSemana
      });
      if (array.includes(ent.value)) {
        //eliminar elemento del array
        var index = array.indexOf(ent.value);
        if (index > -1) {
          array.splice(index, 1);
        }
      } else {
        //Añadir al array
        array.push({ value: ent.value, label: ent.title });
      }
    });

    this.state.entradas.forEach(e => {
      if (array.includes(e.value)) {
        arrayFinal.push({ value: e.value, label: e.label });
      }
    });

    await this.setState({
      numeroSemana: this.state.numeroSemana + 1,
      entradasOrdenadas: [...this.state.entradasOrdenadas, ...arrayEntradas],
      entradas: [...arrayFinal],
      entradaSemana: []
    });

    if (this.state.entradas.length === 0) {
      this.setState({ alertAsignar: false });
    }
  };

  asignarCurso = async event => {
    if (
      this.state.entradas.length !== 0 ||
      this.state.usuariosSelect.length === 0
    ) {
      event.preventDefault();
      await this.setState({ alertAsignar: true });
    } else {
      this.setState({
        loading: true
      });

      let entradasFechaInicio = [];

      entradasFechaInicio = this.state.entradasOrdenadas.map(z => {
        let fechaini = moment(this.state.init_date).utc();
        return {
          _id: z._id,
          fecha_inicio: fechaini.add(z.semana - 1, "week")
        };
      });

      // console.log(entradasFechaInicio);

      let arrayId = [];

      this.state.usuariosSelect.forEach(use => {
        arrayId.push(use.value);
      });

      await this.setState({
        selectedUsersId: arrayId
      });
      //Post de avanceTest
      for (let j = 0; j < this.state.selectedUsersId.length; j++) {
        let avancetest = {
          user: this.state.selectedUsersId[j],
          exam: this.state.cursoSelected.exam._id,
          curso: this.state.cursoSelected._id,
          avance: []
        };
        const uriAvanceT = "/avancetests";
        const method = "POST";
        const token = auth.getToken();
        fetch(constantes("URL_API") + uriAvanceT, {
          method,
          body: JSON.stringify(avancetest),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(data => data.json())
          .then(response => {
            if (response.statusCode === 400) throw new Error(response.message);
          })
          .catch(err => console.log("error:" + err));
      }
      //Añado el campo obligatorio a avancecurso
      let arrayAvance = [];
      let obl = [];
      if (this.state.cursoSelected.obligatorias !== []) {
        this.state.cursoSelected.obligatorias.forEach(o => {
          obl.push(o._id);
        });
      }
      let ents = [];
      this.state.cursoSelected.entrada.forEach(e => {
        ents.push(e._id);
      });

      arrayAvance = entradasFechaInicio.map(x => {
        const tipo = "entrada";
        return {
          _id: x._id,
          status: null,
          tipo,
          position: 0,
          obligatorio: obl.includes(x._id) ? true : false,
          fecha_inicio: ents.includes(x._id) ? x.fecha_inicio : x.fecha_inicio
        };
      });
      arrayAvance.push({
        _id: this.state.cursoSelected.exam._id,
        status: null,
        tipo: "test",
        position: 0
      });

      // console.log(arrayAvance);

      for (let i = 0; i < this.state.usuariosSelect.length; i++) {
        let avancecurso = {
          name: this.state.cursoSelected.nombre,
          cursos: this.state.cursoSelected._id,
          init_date: this.state.init_date,
          solviacanal: this.state.selectedsolviacanal.value,
          solviarol: this.state.selectedsolviarol.value,
          end_date: this.state.end_date,
          user: this.state.selectedUsersId[i],
          avance: arrayAvance
        };

        // console.log(avancecurso)
        //post de avanceCurso
        const method = "POST";
        const token = auth.getToken();
        const uriAvance = "/avancecursos";
        fetch(constantes("URL_API") + uriAvance, {
          method,
          body: JSON.stringify(avancecurso),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        })
          .then(data => data.json())
          .then(response => {
            //Post a myNotification para cuando empieza el curso

            if (response.user.token || response.user.token !== "" || response.user.token !== null || response.user.token !== undefined) {
              let avCursoNot = {
                title: "Nuevo curso",
                body:
                  "Hola ya puedes comenzar el curso: " +
                  this.state.cursoSelected.nombre,
                date: this.state.init_date,
                users: [response.user._id],
                data: { curso: response._id },
                cursos: this.state.cursoSelected._id
              };

              request(constantes("URL_API") + "/mynotifications", {
                method: "POST",
                body: avCursoNot
              });
            }

            this.limpiezaEstados();
            this.componentDidMount();
            this.setState({
              loading: false
            });
            if (response.statusCode === 400) throw new Error(response.message);
          })
          .catch(err => console.log("error:" + err));
      }
    }

    //Post a myNotification para cuando se asigna el curso
    // console.log(this.state.cursoSelected)
    let fechaIni = moment(this.state.init_date).format("DD/MM/YYYY");
    let notAsig = {
      title: "Nuevo curso",
      body:
        "Hola, has sido asignado al curso: \n" +
        this.state.cursoSelected.nombre +
        ". Comenzará el día " +
        fechaIni,
      date: new Date(),
      users: this.state.selectedUsersId,
      // data: {"curso" : this.state.cursoSelected._id},
      cursos: this.state.cursoSelected._id
    };

    request(constantes("URL_API") + "/mynotifications", {
      method: "POST",
      body: notAsig
    });
  };

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading
    });
  };

  render() {
    return (
      <div className="main_content listado">
        <div>
          <h3>Cursos</h3>
        </div>
        <div className="rounded_cont contents shadow">
          <div className="row">
            <div className="col">
              <Loading loading={this.state.loading} />
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className={this.props.className + "marcomodal"}
              >
                <ModalBody className="registroUser">
                  <Cursos
                    toggleLoading={this.toggleLoading}
                    closeModal={this.closeModal}
                    idCursoEdited={this.state.idEditando}
                    isEditing={this.state.isEditing}
                    reloadLista={this.componentDidMount}
                  />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={this.state.modalUsuarios}
                toggle={this.toggleShowUsers}
                className={this.props.className + "marcomodal"}
              >
                <ModalBody className="registroUser">
                  <div className="main_content listado">
                    <div className="rounded_cont contents shadow">
                      <ReactTable
                        data={this.state.estadoUsuarios}
                        columns={[
                          {
                            Header: "Usuario",
                            accessor: "user",
                            Cell: row => <div className="name">{row.value}</div>
                          },
                          {
                            Header: "Estado del curso",
                            accessor: "state",
                            Cell: row => (
                              <div className="retos">{row.value}</div>
                            )
                          }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                style={{ marginTop: "150px" }}
                isOpen={this.state.modalAsignar}
                toggle={this.toggleAsignar}
                className={this.props.className + "marcomodal"}
              >
                <ModalBody className="registroUser">
                  <Form>
                    <h3>{this.state.cursoNombre}</h3>
                    <LoadUser
                      loading={this.loading}
                      cursesolviarols={this.state.cursesolviarols}
                      cursesolviacanals={this.state.cursesolviacanals}
                      cursestores={this.state.cursestores}
                      usuariosSelect={this.usuariosSelect}
                      solviacanals={this.state.solviacanals}
                      solviarols={this.state.solviarols}
                      stores={this.state.stores}
                    />
                    <Row>
                      <Col className="listacursos-col">
                        <Select
                          isMulti
                          options={this.state.users}
                          className="form-control rounded-select rounded_cont shadow"
                          onChange={this.handleChangeUsers}
                          value={this.state.usuariosSelect}
                          placeholder={"Usuarios"}
                        />
                      </Col>
                    </Row>
                    <Row className="listacursos-col">
                      <Col>
                        <span className="publish_date">Fecha de inicio:</span>
                        {this.state.programada ? (
                          <DateTimePicker
                            className="rounded_cont calendar"
                            onChange={date =>
                              this.setState({ init_date: date })
                            }
                            value={this.state.init_date}
                          />
                        ) : (
                            <Moment format="DD [de] MMMM [de] YYYY [ a las ] HH:mm">
                              {this.state.init_date}
                            </Moment>
                          )}
                      </Col>
                      <Col>
                        <span className="publish_date">Fecha de fin:</span>
                        {this.state.programada ? (
                          <DateTimePicker
                            className="rounded_cont calendar"
                            onChange={date => this.setState({ end_date: date })}
                            value={this.state.end_date}
                          />
                        ) : (
                            <Moment format="DD [de] MMMM [de] YYYY [ a las ] HH:mm">
                              {this.state.end_date}
                            </Moment>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="spanSemana">
                        <span>Semana {this.state.numeroSemana}</span>
                      </Col>
                      <Col className="listacursos-col">
                        <Select
                          isMulti
                          options={this.state.entradas}
                          className="form-control rounded-select rounded_cont shadow"
                          onChange={this.handleChangeEntradaSemana}
                          value={this.state.entradaSemana}
                          placeholder={"Entradas"}
                        />
                      </Col>
                      <Col>
                        <Button
                          className="btn-gradient-style2"
                          onClick={this.addSemana}
                        >
                          Añadir Semana
                        </Button>
                      </Col>
                    </Row>
                    {this.state.alertAsignar && (
                      <Row>
                        <span className="spanAlert">
                          Faltan contenidos por agrupar o no has seleccionado
                          ningún usuario
                        </span>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <Button
                          className="btn-gradient-style"
                          onClick={this.asignarCurso}
                        >
                          Asignar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <input
                type="text"
                placeholder="Nombre del Curso"
                onChange={this.filtracursos}
                className="form-control list-search"
              />
              <ReactTable
                data={this.state.cursos}
                columns={[
                  {
                    Header: "",
                    accessor: "_id",
                    width: 40,
                    Cell: row => (
                      <div className="">
                        <input
                          name={row.value}
                          id={row.value}
                          type="checkbox"
                          onChange={() => this.gestionaBloque(row.value)}
                          checked={
                            this.state.selectBloque.includes(row.value)
                              ? true
                              : false
                          }
                        />
                        <div className="state" />
                      </div>
                    )
                  },
                  {
                    Header: "Nombre del Curso",
                    accessor: "nombre",
                    //width: 120,
                    Cell: row => <div className="name">{row.value}</div>
                  },
                  {
                    Header: "Nº de contenidos",
                    accessor: "entrada.length",
                    width: 80,
                    headerClassName: "retos",
                    Cell: row => <div className="retos">{row.value}</div>
                  },
                  {
                    Header: "Usuarios",
                    accessor: "avancecursos",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        {/* <span onClick={() =>{this.toggleShowUsers(row.value)}}><i className="fas fa-users"/></span>   */}
                        <TablaParticipantes courseID={"curso"} row={row} />
                      </div>
                    )
                  },
                  {
                    Header: "Asignar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span
                          onClick={() => {
                            this.toggleAsignar(row.value);
                          }}
                        >
                          <i className="fas fa-archive" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Duplicar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span
                          onClick={() => {
                            this.duplicaCurso(row.value);
                          }}
                        >
                          <i className="fas fa-copy" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span
                          onClick={() => {
                            this.toggle(row.value);
                          }}
                        >
                          <i className="fas fa-pencil-alt" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="delete">
                        <span
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Estás seguro que quieres eliminar este curso?"
                              )
                            ) {
                              this.deletecurso(row.value);
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash-alt" />
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
            {this.state.selectBloque.length > 0 ? (
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10">
                    {" "}
                    <span>
                      Has seleccionado {this.state.selectBloque.length}{" "}
                      {this.state.selectBloque.length < 2 ? "curso" : "cursos"}.
                    </span>
                  </div>
                  <div className="col-2 text-center">
                    {" "}
                    <span
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres eliminar estos cursos?"
                          )
                        ) {
                          this.state.selectBloque.forEach(element =>
                            this.deletecurso(element)
                          );
                        }
                        this.setState({ selectBloque: [] });
                      }}
                    >
                      {" "}
                      <i className="fas fa-trash-alt" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
                ""
              )}
          </div>
        </div>

        <div className="col">
          <div className="col-3">
            <Button
              className="btn-gradient-style"
              onClick={() => this.toggle()}
            >
              Añadir curso
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
