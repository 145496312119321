import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import Header from '../Header';
import NavBarVertical from '../../components/NavBarVertical';
import Contents from '../Contents';
import SmartEstadistica from '../SmartEstadistica';
import ContentAnalytics from '../ContentAnalytics';
import GeneralAnalytics from '../GeneralAnalytics';
import NewContent from '../NewContent';
import Cursos from '../Cursos';
import ListaCursos from '../ListaCursos';
import Comments from '../Comments';
import Stores from '../Stores';
import Categorias from '../Categorias';
import Media from '../Media';
import Content from '../Content';
import Training from '../Training';
import Focus from '../Focus';
import Users from '../Users';
import Retos from '../Retos';
import Questions from '../Questions';
import SmartLearning from '../SmartLearning';
import Itinerarios from '../Itinerarios';
import Metricas from '../Metricas';
import Profile from '../Profile';
import Notification from '../Notification';
import Webinars from '../Webinars'
import Convocatoria from '../Convocatoria'
import './styles.css';
import PrivateRoute from '../PrivateRoute';
import './styles.css';
import RoleManager from '../RoleManager';
import Channel from '../Channel';
import ExamRef from '../Exam/ExamRef';

class Main extends Component {
  
  render() {
    return (
          <div className = "main_container">
            <Header/>
            <div className = "content">
              <div className = "row">
                <div className="col-lg-3">
  
                    {/* URLs de inicio */}
                    
                    <PrivateRoute exact allowed={["Administrator"]} path='/content' component={NavBarVertical}/>
                    <PrivateRoute exact allowed={["Administrator"]} path='/training' component={NavBarVertical}/>
                    <PrivateRoute exact allowed={["Administrator"]} path='/focus' component={NavBarVertical}/>
                    <PrivateRoute exact allowed={["Administrator"]} path='/smartlearning' component={NavBarVertical}/>
                    <PrivateRoute exact allowed={["Administrator"]} path='/' component={NavBarVertical}/>
                    
                    {/* URLs dinámicas */}
                    <PrivateRoute allowed={["Administrator"]} path='/edit/:id' component={NavBarVertical}/>

                    {/* URLs de áreas de gestion */}
                    <PrivateRoute allowed={["Administrator"]} path='/categorias' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/contents' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/analytics' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/comments' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/itinerarios' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/exam' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/logros' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/media' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/stores' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/new' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/contentanalytics' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/generalanalytics' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/cursos' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/listadocursos' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/notification' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/profile' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/retos' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/questions' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/users' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/roles' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/channel' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/metricas' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/webinars' component={NavBarVertical}/>
                    <PrivateRoute allowed={["Administrator"]} path='/convocatoria' component={NavBarVertical}/>
                    
                    

                </div>
                <div className="col-lg-9 up_content">

                    <Switch>

                      {/* URLs de inicio */}
                      <PrivateRoute exact allowed={["Administrator"]} path='/' component={Content}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/content' component={Content}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/smartlearning' component={SmartLearning}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/training' component={Training}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/focus' component={Focus}/>
                      

                      {/* URLs dinámicas */}
                      <PrivateRoute exact allowed={["Administrator"]} path='/edit/:id' component={NewContent}/>

                      {/* URLs de áreas de gestion */}
                      <PrivateRoute allowed={["Administrator"]} path='/profile' component={Profile}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/new' component={NewContent}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/cursos' component={Cursos}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/listadocursos' component={ListaCursos}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/comments' component={Comments}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/contents' component={Contents}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/analytics' component={SmartEstadistica}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/contentanalytics' component={ContentAnalytics}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/generalanalytics' component={GeneralAnalytics}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/categorias' component={Categorias}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/logros' component={Retos}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/media' component={Media}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/users' component={Users}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/stores' component={Stores}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/itinerarios' component={Itinerarios}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/exam' component={ExamRef}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/roles' component={RoleManager}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/channel' component={Channel}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/retos' component={Retos}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/questions' component={Questions}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/notification' component={Notification}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/webinars/:id' component={Webinars}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/convocatoria' component={Convocatoria}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/' component={Content}/>
                      <PrivateRoute exact allowed={["Administrator"]} path='/metricas' component={Metricas}/>
                    </Switch>

                </div>
              </div>
            </div>
          </div>
      
    );
  }
}

export default Main;
