import React, { Component } from 'react';
import { Button, Alert, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, FormText, Row } from 'reactstrap';
import ReactTable from "react-table";
import Mailto from 'react-protected-mailto';
import Loading from '../../components/Loading';
import MassiveUsers from '../../components/MassiveUsers'
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';
import auth from '../../utils/auth';
import moment from 'moment';
import Select from "react-select";



class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertaPass: false,
      chooseAdmin: false,
      confirmPass: '',
      editId: '',
      idAvatar: '/img/userplaceholder.jpg',
      idEditando: '',
      idRol:'',
      loading: false,
      mandamail: false,
      modal: false,
      modalEditUser: false,
      modalPass: false,
      muestraPass: '',
      newApellido: '',
      newAvatar: '',
      newEmail: '',
      newEstado: true,
      newName: '',
      newPass: '',
      newRole: '',
      newChanel:'', 
      newStore:[],
      newUsername: '',
      newDNI:'',
      newCiudad:'',
      newCP:'',
      newAutoasignacion: false,
      photo: '/img/userplaceholder.jpg',
      roles: [],
      canales: [],
      stores: [],
      selectBloque: [],
      usuarios: [],
      zonasAdmin: {content:true,training:false,farming:false,smartlearning:false},
      zonasApp: {content:true,training:false,farming:false,smartlearning:false},

    };
    this.addUser = this.addUser.bind(this);
    this.cierraTogglePas = this.cierraTogglePas.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.enviaMail = this.enviaMail.bind(this);
    this.filtraUsers = this.filtraUsers.bind(this);
    this.gestionaBloque = this.gestionaBloque.bind(this);
    this.handleNew = this.handleNew.bind(this);
    this.handleNewUserName = this.handleNewUserName.bind(this);
    this.handleNewavatar = this.handleNewavatar.bind(this);
    this.handleNewemail = this.handleNewemail.bind(this);
    this.handleNewpass = this.handleNewpass.bind(this);
    this.handleNewrole = this.handleNewrole.bind(this);
    this.handlechangeAvatar = this.handlechangeAvatar.bind(this);
    this.handlemandaMail = this.handlemandaMail.bind(this);
    this.ocultaGenPass = this.ocultaGenPass.bind(this);
    this.openPass = this.openPass.bind(this);
    this.printComments = this.printComments.bind(this);
    this.randomPass = this.randomPass.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.storeAvatar = this.storeAvatar.bind(this);
    this.storechangeAvatar = this.storechangeAvatar.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglePass = this.togglePass.bind(this);
  }
  limpiezaEstados() {
    this.setState({
      alertaPass: false,
      confirmPass: '',
      editId: '',
      idAvatar: '/img/userplaceholder.jpg',
      idEditando: '',
      idRol: '',
      loading: false,
      mandamail: false,
      muestraPass: '',
      newApellido: '',
      newAvatar: null,
      newEmail: '',
      newEstado: true,
      newName: '',
      newPass: '',
      newRole: '',
      newChanel:'',
      newRoleName: '',
      newUsername: '',
      newDNI:'',
      newCiudad:'',
      newCP:'',
      newAutoasignacion: false,
      photo: '/img/userplaceholder.jpg',
      preEmail: '',
      preUsername: '',
      zonas_admin: {},
      zonas_app: {content:false,training:false,farming:false,smartlearning:false},
      zonasApp: {content:true,training:false,farming:false,smartlearning:false},
    })
  }
  filtraUsers(value) {
    if (value.target.value !== "" || value.target.value !== undefined) {
      let dataFiltro = this.state.usuarios2.filter(array => array.email.includes(value.target.value))
      this.setState({ usuarios: dataFiltro })
    } else {
      this.setState({ usuarios: this.state.usuarios2 })
    }
  }

  enviaMail() {
    let mailbody = {
      "from": "soporte@hub7s.com",
      "to": this.state.newEmail,
      "text": this.state.idEditando === '' ? "¡Bienvenido a la nueva APP Somos Seal! Para descargarte la nueva APP es muy sencillo, tan sólo tienes que seguir estos pasos: 1.Antes de nada debes desinstalarte la APP Somos Seal que tengas instalada en tu smartphone. 2.Descargar la nueva APP en Play Store poniendo en el buscador ‘Somos Seal’. Está disponible para el sistema operativo Android y en las próximas semanas estará para el sistema iOS (iPhone). 3.Introducir tu usuario '" + this.state.newUsername + "' y tu contraseña '" + this.state.newPass + "'. Si tienes alguna incidencia, por favor, contacta con nosotros a través del correo somosseals@solvia.es poniendo en el asunto ‘Incidencia acceso aplicación Somos Seal’. A partir de este momento comienza a disfrutar de todas la ventajas que te ofrece la NUEVA APP SOMOS SEAL. ¡Esperamos que las disfrutéis!" : "Muy buenas, " + this.state.newUsername + ", hemos generado una nueva contraseña en tu cuenta de Somos Seal: " + this.state.newPass + ". Si necesitas modificarla puedes hacerlo en cualquier momento desde la opción Cambiar Contraseña de tu perfil. Un Saludo. Soporte Somos Seal.",
      "html": this.state.idEditando === '' ? "<p><b><i>¡Bienvenido a la nueva APP Somos Seal!</i></b></p><br/><p><i>Para descargarte la nueva APP es muy sencillo, tan sólo tienes que seguir estos pasos:</i></p><br/><i><ol><li>Antes de nada debes desinstalarte la APP Somos Seal que tengas instalada en tu smartphone.</li><li>Descargar la nueva APP en Play Store poniendo en el buscador ‘Somos Seal’. Está disponible para el sistema operativo Android y en las próximas semanas estará para el sistema iOS (iPhone).</li><li>Introducir tu usuario '" + this.state.newUsername + "' y tu contraseña '" + this.state.newPass + "'. </li></ol><br/><p>Si tienes alguna incidencia, por favor, contacta con nosotros a través del correo <a href='mailto:somosseals@solvia.es?Subject=Incidencia%20acceso%20aplicación%20Somos%20Seal' target='_top'>somosseals@solvia.es</a> poniendo en el asunto ‘Incidencia acceso aplicación Somos Seal’.</p><br/><p>Haz click <a href='https://s3-eu-west-3.amazonaws.com/blacknosaur/wp-content/uploads/2018/11/13134415/Manual_uso_APP_Somos_Seal.pdf'>aquí</a> para ver la ‘Guía de uso de la nueva APP Somos Seal’.</p><br/><p>A partir de este momento comienza a disfrutar de todas la ventajas que te ofrece la NUEVA APP SOMOS SEAL.</p><p><b>¡Esperamos que las disfrutéis!</b></p></i>" : "<i><p>Muy buenas, " + this.state.newUsername + ", hemos generado una nueva contraseña en tu cuenta de Somos Seal: " + this.state.newPass + "</p><br/><p>Si necesitas modificarla puedes hacerlo en cualquier momento desde la opción <b>Cambiar Contraseña</b> de tu perfil.</p><br/><p>Un Saludo.</p><br/><p>Soporte Somos Seal.</p></i>",
      "subject": this.state.idEditando === '' ? "Bienvenido a Somos Seal" : "Recupera tu acceso de Somos Seal"
    }
    request(constantes("URL_API") + "/email", {
      method: "POST",
      body: mailbody,
    })
  }
  setLoading(loading) {
    this.setState({
      loading
    });
  }
  reassignCourses = async(idEditando) =>    
        
  {
    // console.log('entro a reasignar')      
    //1º Eliminar los avancecursos que ya no nos corresponden
    let cursouser = await request(constantes("URL_API") + "/graphql?query=%7B%0A%20user(id%3A%22"+idEditando+"%22)%7B%20%20%20%20%0A%20%20%20%20solviaRol%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%20%20%0A%20%20%20%20solviaCanal%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20avancecursos(where%3A%20%7Bestao_ne%3A%20%22completado%22%20%7D)%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20estao%20%0A%20%20%20%20%20%20cursos%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%20nombre%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20solviarol%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%20%7D%20%20%0A%20%20%20%20%20%20solviacanal%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D", { method: "GET" })
    let cursosErroneos = //averiguamos qué cursos no se corresponden con el rol/canal actual
      cursouser.data.user.avancecursos
        .filter(e => 
            (e.solviarol!==null&&e.solviarol !== this.state.newRole) &&  (e.solviacanal!==null&&e.solviacanal !== this.state.newChanel)
          )
    cursosErroneos.map(element =>{
      request(constantes("URL_API") + "/avancecursos/" + element._id, { method: "DELETE" }).catch(err => console.log(err));      
    })
  //2º Averiguar los ID de los cursos son accesibles debido a tu rol y canal.
    //2.1 ¿Qué cursos están activos?
    let nuRole = this.state.newRole
    let nuChanel = this.state.newChanel
    let availableCourses = await request(constantes("URL_API") + "/graphql?query=%7B%0A%20%0A%20%20cursos%7B%0A%20%20%20%20_id%0A%20%20%20%20solviarols%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20solviacanals%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20stores%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%20%20avancecursos%7B%0A%20%20%20%20%20%20estao%0A%20%20%20%20%20%20end_date%0A%20%20%20%20%20%20user%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20cursos%7B%0A%20%20%20%20%20%20%20%20_id%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D", { method: "GET" }).catch(err => console.log(err));
    let assignableCourses = availableCourses.data.cursos
      .filter((e) =>          
       ((e.solviarols.length>0&&(e.solviarols.some(u => u._id === this.state.newRole) === true?true:false))||
       (e.stores.length>0&&(e.stores.some(u => u._id === this.state.newRole) === true?true:false))||
       (e.solviacanals.length>0&&(e.solviacanals.some(u => u._id ===this.state.newChanel) ===true?true:false)))
       &&e.avancecursos.some(elemento => moment(elemento.end_date).isAfter(new Date(), "day")===true)) //filtramos que tengan el rol o canal correcto    
      .map(e => e._id) // reducimos el array de objetos a IDs
      .filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual)//Eliminamos elementos repetidos
      // console.log(assignableCourses)
  //3º Comprobamos cada ID, si ya hay un avancecurso asignado a nosotros con ese id, no hacemos nada. Si no, se crea Avancecurso+AvanceTest
    assignableCourses.map(async e =>{     
        if (Array.prototype.concat.apply([], availableCourses.data.cursos.map(e =>e.avancecursos)).filter(a =>a.user!==null).some(a => a.cursos._id === e && a.user._id === idEditando) === false) {
          
          let course = await request(constantes("URL_API") + "/graphql?query=%7B%0A%20curso(id%3A%22" + e + "%22)%7B%20%20%20%20%0A%09%09_id%0A%20%20%09nombre%0A%20%20%09exam%7B%0A%20%20%20%20%09_id%0A%20%20%09%7D%0A%20%20%09entrada%7B%0A%20%20%20%20%20%20_id%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D", { method: "GET" }).catch(err => console.log(err));
          
          //crear avancecurso y avancetest
          //Post de avanceTest
          let avancetest = {
            user: idEditando,
            exam: course.data.curso.exam._id,
            avance: []
          }
          let avancecurso = {
            name: course.data.curso.nombre,
            cursos: course.data.curso._id,
            init_date: new Date(),
            solviacanal: nuChanel===""?null:nuChanel,
            solviarol: nuRole===""?null:nuRole,
            end_date: new Date().setMonth(new Date().getMonth()+3),
            user: idEditando,
            avance: course.data.curso.entrada.map(x => {

              const tipo = "entrada";

              return ({
                _id: x._id,
                status: null,
                tipo,
                position: 0
              })
            })
          }         
          const uriAvanceT = "/avancetests";
          const method = "POST";
          const token = auth.getToken();
          fetch(constantes("URL_API") + uriAvanceT, {
            method,
            body: JSON.stringify(avancetest),
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              'Authorization': `Bearer ${token}`
            }
          }).then(data => data.json())
            .then(response => {
              if (response.statusCode === 400) throw new Error(response.message);
            }).catch(err => console.log("error:" + err));

          //post de avanceCurso  
          const uriAvance = "/avancecursos";
          fetch(constantes("URL_API") + uriAvance, {
            method,
            body: JSON.stringify(avancecurso),
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              'Authorization': `Bearer ${token}`
            }
          }).then(data => data.json())
            .then(response => {
              if (response.statusCode === 400) throw new Error(response.message);
            }).catch(err => console.log("error:" + err));
        }
      })
    }
  randomPass() {
    var generator = require('generate-password');
    var password = generator.generate({
      length: 10,
      numbers: true,
      strict: true
    });
    this.setState({
      confirmPass: password,
      muestraPass: password,
      newPass: password,
      randomGenerado: true,
    });
  }
  actualizaUsuarios(id) {
    const usuariosMod = [...this.state.usuarios]; //copia el estado
    usuariosMod.find(e => e._id === id).status = !(usuariosMod.find(e => e._id === id).status);
    this.setState({ usuarios: usuariosMod });
  }
  toggle(id = "") {
    //si está abierto lo cerramos
    if (this.state.modal === true) {
      this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal

      });
    } else {
      //compruebo que tenga ID válido
      if (id !== "") {
        //Si tiene ID, cargamos la data del ID y abrimos.        
        request(constantes("URL_API") + "/users/" + id, { method: "GET" })
          .then((user) => {
            console.log(user)
            this.setState({
            editUserData: user,
            idEditando: id,
            mailRef: user.email,
            muestraPass: '',
            newApellido: user.surname,
            newEmail: user.email,
            newEstado: user.status,
            newName: user.name,
            newPass: user.password,
            newRole: user.solviaRol? user.solviaRol._id:'',
            newChanel: user.solviaCanal?user.solviaCanal._id:'',
            newStore: user.store?user.store._id:'',
            newRoleName: user.solviaRol?user.solviaRol.name:'',
            newUsername: user.username,
            newDNI: user.dni,
            newCiudad: user.ciudad,
            newCP: user.cp,
            photo: user.picture ? user.picture.url : '/img/userplaceholder.jpg',
            preEmail: user.email,
            preUsername: user.username,
            zonasAdmin: user.zonas_admin,
            zonasApp: user.zonas_app,
            modal: !this.state.modal,
          })})
          ;

      } else {
        //Si no tiene ID, abrimos el modal
        this.setState({
          modal: !this.state.modal
        });
      }
    }
  }
  handlemandaMail() {
    this.setState({
      mandamail: !this.state.mandamail
    });
  }
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id)) {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({ selectBloque: array });
  }

  openPass(id) {
    request(constantes("URL_API") + "/graphql?query=%7B%0A%20%20user(id%3A%20%22" + id + "%22)%7B%20%20%20%0A%20%20%20%20username%0A%20%20%20%20name%0A%20%20%20%20surname%0A%20%20%20%20email%0A%20%20%7D%0A%7D%0A", { method: "GET" })
      .then((usuarios) => this.setState({
        confirmPass: usuarios.data.user.password,
        editUserData: usuarios.data.user,
        idEditando: id,
        mailRef: usuarios.data.user.email,
        muestraPass: '',
        newEmail: usuarios.data.user.email,
        newPass: usuarios.data.user.password,
        newUsername: usuarios.data.user.username,
      }));
    this.setState({ modalPass: true })
  }
  togglePass(id) {
    this.state.modalPass === false ? this.openPass(id) : this.cierraTogglePas()
  }
  cierraTogglePas() {
    this.limpiezaEstados();
    this.setState({ modalPass: false });
  }
  async editUser(id) {
    if (this.state.muestraPass === this.state.confirmPass) {
      let coordenadas = await fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          encodeURI(this.state.newCiudad!==''?this.state.newCiudad:"Alicante" + this.state.newCP?this.state.newCP:"03001") +
          "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I"
      );
      let coordenadasjson = await coordenadas.json();        
        // console.log(coordenadasjson.results);
        let res_lng = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lng:1;
        let res_lat = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lat:1;

      if (this.state.newEmail === this.state.mailRef) {
        let user = {
          username: this.state.newUsername,
          dni: this.state.newDNI,
          ciudad: this.state.newCiudad,
          cp: this.state.newCP,
          lat: res_lat,
          lng: res_lng,
          name: this.state.newName,
          surname: this.state.newApellido,
          solviaRol: this.state.newRole!==""?this.state.newRole:null,
          solviaCanal: this.state.newChanel!==""?this.state.newChanel:null,
          // store: this.state.newStore!==[]?this.state.newStore.map(e => e.value):null,
          store: this.state.newStore!==[]?this.state.newStore:null,
          status: this.state.newEstado,
          zonas_app: this.state.zonasApp,
        }
        console.log(user)
        request(constantes("URL_API") + "/users/" + id, {
          method: "PUT",
          body: user,
        }).then(async response => {
          this.handlechangeAvatar("user", response._id);
          // await this.reassignCourses(this.state.idEditando)
          this.limpiezaEstados();
          this.componentDidMount();
        }).then(() => this.setState({
          modal: !this.state.modal
        }))
      } else {
        let user = {
          username: this.state.newUsername,
          dni: this.state.newDNI,
          ciudad: this.state.newCiudad,
          cp: this.state.newCP,
          lat: res_lat,
          lng: res_lng,
          email: this.state.newEmail,
          name: this.state.newName,
          surname: this.state.newApellido,
          solviaRol: this.state.newRole,
          solviaCanal: this.state.newChanel,
          status: this.state.newEstado,
          zonas_app: this.state.zonasApp
        }
        request(constantes("URL_API") + "/users/" + id, {
          method: "PUT",
          body: user,
        }).then(async response => {
          this.handlechangeAvatar("user", response._id);
          // await this.reassignCourses(this.state.idEditando)
          this.limpiezaEstados();
          this.componentDidMount();
        }).then(() => this.setState({
          modal: !this.state.modal
        }))
      };
      this.setState({ alertaPass: false, randomGenerado: false, })
    } else { this.setState({ alertaPass: true, randomGenerado: false, }) }
  }
  editPass(id) {
    if (this.state.muestraPass === this.state.confirmPass && this.state.confirmPass !== "") {
      if (this.state.mandamail === true) { this.enviaMail(); }
      if (this.state.newEmail === this.state.mailRef) {
        let user = {
          password: this.state.newPass
        }
        request(constantes("URL_API") + "/users/" + id, {
          method: "PUT",
          body: user,


        }).then(response => {
          this.limpiezaEstados();
          this.componentDidMount();

        }).then(() => this.setState({
          modalPass: !this.state.modalPass
        }))
      } else {
        let user = {
          email: this.state.newEmail,
          password: this.state.newPass
        }
        request(constantes("URL_API") + "/users/" + id, {
          method: "PUT",
          body: user,


        }).then(response => {
          this.handlechangeAvatar("user", response._id);
          this.limpiezaEstados();
          this.componentDidMount();
        }).then(() => this.setState({
          modalPass: !this.state.modalPass
        }))
      };
      this.setState({ alertaPass: false, randomGenerado: false, })


    } else { this.setState({ alertaPass: true }) }


  }

  async addUser() {
    if (this.state.mandamail === true) { this.enviaMail(); }
    if (this.state.newPass === this.state.confirmPass && this.state.newPass !== "") {
      let coordenadas = await fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          encodeURI(this.state.newCiudad!==''?this.state.newCiudad:"Alicante" + this.state.newCP?this.state.newCP:"03001") +
          "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I"
      );
      let coordenadasjson = await coordenadas.json();        
        // console.log(coordenadasjson.results);
        let res_lng = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lng:1;
        let res_lat = coordenadasjson.results.length > 0? await coordenadasjson.results[0].geometry.location.lat:1;
      let user = {
        date: new Date(),
        email: this.state.newEmail,
        name: this.state.newName,
        password: this.state.newPass,
        role: this.state.idRol,
        solviaRol: this.state.newRole,
        solviaCanal: this.state.newChanel,
        store:this.state.newStore,
        status: this.state.newEstado,
        surname: this.state.newApellido,
        username: this.state.newUsername,
        dni: this.state.newDNI,
        zonas_admin: this.state.zonasAdmin,
        zonas_app: this.state.zonasApp,
        ciudad: this.state.newCiudad,
        cp: this.state.newCP,
        lat: res_lat,
        lng: res_lng,
      }
      request(constantes("URL_API") + "/users", {
        method: "POST",
        body: user,


      }).then(async response => {
        
        this.handleNewavatar("users", response._id);
        this.state.newAutoasignacion===true&&await this.reassignCourses(response._id);        
        this.limpiezaEstados();
        this.componentDidMount();
      }).then(() => this.setState({
        modal: !this.state.modal
      }))
      this.setState({ alertaPass: false, randomGenerado: false })



    } else { this.setState({ alertaPass: true }) }


  }



  deleteUser(id) {
    request(constantes("URL_API") + "/graphql?query=%7B%0A%20user(id%3A%22" + id + "%22)%7B%20%20%20%20%0A%20%20%20%20_id%0A%20%20avancetests%7B%0A%20%20%20%20_id%0A%20%20%09%7D%0A%20%20avancecursos%7B%0A%20%20%20%20_id%0A%20%20%09%7D%0A%7D%0A%7D", { method: "GET" })
      .then((response) => {
        response.data.user.avancetests&&response.data.user.avancetests.map(e =>{
          request(constantes("URL_API") + "/avancetests/" + e._id, { method: "DELETE" });
        })
        response.data.user.avancecursos&&response.data.user.avancecursos.map(e =>{
          request(constantes("URL_API") + "/avancecursos/" + e._id, { method: "DELETE" })
        })
      });
    request(constantes("URL_API") + "/users/" + id, { method: "DELETE" })
      .then((response) => this.componentDidMount());
  }
  handleNewUserName(value) {
    this.setState({
      newUsername: value.target.value,
      unidadUsername: this.state.usuarios3
    });
    this.state.usuarios2
      .forEach(function (element) {
      if (value.target.value === element.username && value.target.value!==this.state.preUsername) {
        let e = this.state.unidadUsername - 1;
        this.setState({ unidadUsername: e })
      }
    }.bind(this)
    )
  }
  handleNewrole(value) {
    this.setState({
      loading: true,
      newRole: value.target.value
    });
    this.setLoading(false);

  }
  handleNewstore = (value) => {
    // console.log(value)
    this.setState({
      loading: true,
      newStore: value.target.value
    });
    this.setLoading(false);

  }
  handleNewchanel= (value) => {
    this.setState({
      loading: true,
      newChanel: value.target.value
    });
    this.setLoading(false);

  }
  handleNewemail(value) {
    this.setState({
      newEmail: value.target.value,
      unidadEmail: this.state.usuarios3
    });
    this.state.usuarios2.forEach(function (element) {
      if (value.target.value === element.email) {
        let e = this.state.unidadEmail - 1;
        this.setState({ unidadEmail: e })
      }
    }.bind(this)
    )
  }
  handleNew(value, concepto) {
    let estado = concepto;
    this.setState({
      [estado]: value.target.value
    });
  }
  handleNewpass(value) {
    this.setState({
      newPass: value.target.value,
      muestraPass: value.target.value
    });
  }
  ocultaGenPass() {
    this.setState({ randomGenerado: false })
  }
  storeAvatar(value) {
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
      });
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ photo: e.target.result });
      };
      reader.readAsDataURL(value.target.files[0]);
    }
  }
  storechangeAvatar(value) {
    if (value.target.files && value.target.files[0]) {
      this.setState({
        idAvatar: value.target.files[0]
      }, () => {
        this.handlechangeAvatar(this.state.idEditando);
      });

    }
  }
  handleNewavatar = async (ref, refId) => {
    const imgToUpload = new FormData();
    imgToUpload.append('files', this.state.idAvatar);
    imgToUpload.append('field', "picture");
    imgToUpload.append('refId', refId);
    imgToUpload.append('ref', "user");
    imgToUpload.append('source', 'users-permissions')
    request(constantes("URL_API") + "/upload", {
      method: 'POST',
      body: imgToUpload,
    }, false)
      .catch(err => console.log(err));
  }
  handlechangeAvatar = async () => {
    // console.log(this.state.idAvatar);
    const imgToUpload = new FormData();
    imgToUpload.append('files', this.state.idAvatar);
    imgToUpload.append('field', "picture");
    imgToUpload.append('refId', this.state.idEditando);
    imgToUpload.append('ref', "user");
    imgToUpload.append('source', 'users-permissions')
    request(constantes("URL_API") + "/upload", {
      method: 'POST',
      body: imgToUpload,
    }, false)
      .catch(err => console.log(err));
  }
  printComments() {
    return this.state.usuarios.map((usuario) => {

      return (

        <tr key={usuario._id}>
          <td className="avatar align-middle"><img src={(usuario.picture != null) ? usuario.picture.url : "/img/userplaceholder.jpg"} alt="" /></td>
          <td className="user align-middle">{usuario.username}</td>
          <td className="email align-middle">{usuario.email}</td>
          <td className="type align-middle">{usuario.role.name}</td>
          <td className="status align-middle">
            {usuario.status !== true ? <span> Usuario no activo</span> : <span> Usuario activo </span>}
          </td>
          <td className="edit align-middle">
            <span onClick={this.toggleEdit(usuario._id)}><i className="fas fa-pencil-alt" /></span>
          </td>
          <td className="delete align-middle">
            <span 
              onClick={() => { if (window.confirm("¿Estás seguro que quieres borrar esto?")) 
              { this.deleteUser(usuario._id) } }}
            > 
              <i className="fas fa-trash-alt" /> 
            </span>
          </td>
        </tr>

      );
    })
  }

  printRoles() {
    return this.state.roles.map((roles) => {
      return (
        <option value={roles._id} key={roles._id}>{roles.name}</option>
      );
    });
  }
  printStores() {
    return this.state.stores.map((stores) => {
      return (
        <option value={stores._id} key={stores._id}>{stores.name}</option>
      );
    });
  }
   
  
  printCanales = () => {
    //console.log(this.state.canales);
    return this.state.canales.map((canales) => {
      return (
        <option value={canales._id} key={canales._id}>{canales.name}</option>
      );
    });
  }
  
  componentDidMount = () => {
    this.setState({ loading: true })
    var consulta = "/graphql?query=%7B%0A%20users(sort%3A%22email%22,%20limit%3A%200)%7B%0A%20%20%20%20picture%7B%0A%20%20%20%20%20%20url%0A%20%20%20%20%7D%0A%20%20%20%20_id%0A%20%20%20%20username%0A%20%20%20%20email%0A%20%20%20%20dni%0A%20%20%20%20status%0A%20%20%20%20zonas_app%0A%20%20%20%20role%7B%0A%20%20%20%20%20%20name%0A%20%20%20%20%7D%0A%20%20solviaRol%7B%0A%20%20%20%20%20name%0A%20%20%09%7D%0A%20%20solviaCanal%7B%0A%20%20%20%20name%0A%20%20%09%7D%0A%20%20name%0A%20%20surname%0A%20%20cp%0A%20%20ciudad%0A%20%20%7D%0A%7D%0A";
    request(constantes("URL_API") + consulta, { method: "GET" })
      .then((usuarios) => {
        if(Object.keys(usuarios).includes("errors")){
          localStorage.clear();
          this.props.history.push('/auth/login');
        }else{
          let zona = localStorage.getItem("admin")
          this.setState({
            unidadEmail: usuarios.data.users.filter(e => e.zonas_app[zona]=== true).length,
            unidadUsername: usuarios.data.users.filter(e => e.zonas_app[zona]=== true).length,
            usuarios2: usuarios.data.users.filter(e => e.zonas_app[zona]=== true),
            usuarios3: usuarios.data.users.filter(e => e.zonas_app[zona]=== true).length,
            usuarios: usuarios.data.users.filter(e => e.zonas_app[zona]=== true),
            usertotal: usuarios.data.users,
            loading: false
         })
      }
        });
    request(constantes("URL_API") + "/users-permissions/roles", { method: "GET" })
      .then((a) => this.setState({
        idRol: a.roles.find((a) => a.name === "Comercial")._id
      })).catch(err=>{
        localStorage.clear();
          this.props.history.push('/auth/login');
      });
        request(constantes("URL_API") + "/solviarols", { method: "GET" })
      .then((a) => this.setState({
        roles: a
      }));
    request(constantes("URL_API") + "/solviacanals", { method: "GET" })
      .then((a) => this.setState({
        canales: a
      }));
    request(constantes("URL_API") + "/stores", { method: "GET" })
      .then((a) => this.setState({
        stores: a
      }));

  }


  render() {
    let addEnable = 
      
      this.state.newUsername !== '' &&
      this.state.unidadUsername === this.state.usuarios3 &&
      this.state.newName !== '' &&
      this.state.newApellido !== '' &&
      this.state.newEmail !== '' &&
      this.state.unidadEmail === this.state.usuarios3 &&
      this.state.newPass !== '' &&
      this.state.confirmPass !== '' ?
      false : true;
    let editEnable =    
    this.state.newUsername !== '' &&
    this.state.unidadUsername === this.state.usuarios3 &&       
    this.state.newName !== '' &&
    this.state.newApellido !== '' &&
    this.state.newEmail !== '' &&
    this.state.unidadEmail === this.state.usuarios3 
    ?
    false : true;
    return (
      <div className="main_content listado">
        <div>
          <h3>Usuarios</h3>
        </div>
        <div className="rounded_cont contents shadow">
          <div className="row">
            <div className="col">
              <Loading loading={this.state.loading} />
              <input
                type="text"
                placeholder="e-mail"
                onChange={this.filtraUsers}
                className="form-control list-search"
              />
              <ReactTable
                data={this.state.usuarios}
                columns={[
                  {
                    Header: "",
                    accessor: "_id",
                    width: 60,
                    Cell: row => (
                      <div className="">
                        <input
                          name={row.value}
                          id={row.value}
                          type="checkbox"
                          onChange={() => this.gestionaBloque(row.value)}
                          checked={
                            this.state.selectBloque.includes(row.value)
                              ? true
                              : false
                          }
                        />
                        <div className="state" />
                      </div>
                    )
                  },
                  {
                    accessor: "picture.url",
                    width: 120,
                    Cell: row => (
                      <div className="avatar shadow">
                        <img
                          src={
                            row.value !== null
                              ? row.value
                              : "/img/userplaceholder.jpg"
                          }
                          alt=""
                        />
                      </div>
                    )
                  },
                  // {
                  //   Header: "Usuario",
                  //   accessor: "username",
                  //   headerClassName: 'user',
                  //   Cell: row => (
                  //     <div className = "user">
                  //       { row.value }
                  //     </div>
                  //   )
                  // },
                  {
                    Header: "Email",
                    accessor: "email",
                    headerClassName: "email",
                    Cell: row => (
                      <div className="email">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.toggle(row.original._id);
                          }}
                        >
                          {row.value}{" "}
                        </span>
                      </div>
                    )
                  },
                  // {
                  //   Header: "Tipo de usuario",
                  //   accessor: "role.name",
                  //   className: "role",
                  //   width: 160,
                  //   Cell: row => (
                  //     <div className = "role">
                  //       { row.value }
                  //     </div>
                  //   )
                  // },
                  {
                    Header: "Estado",
                    accessor: "status",
                    width: 80,
                    Cell: row => (
                      <div className="status">
                        {row.value === true ? "Activo" : "Inactivo"}
                      </div>
                    )
                  },
                  {
                    Header: "Contraseña",
                    accessor: "_id",
                    width: 100,
                    Cell: row => (
                      <div className="edit">
                        <span
                          onClick={() => {
                            this.togglePass(row.value);
                          }}
                        >
                          <i className="fas fa-lock" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Editar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="edit">
                        <span
                          onClick={() => {
                            this.toggle(row.value);
                          }}
                        >
                          <i className="fas fa-pencil-alt" />
                        </span>
                      </div>
                    )
                  },
                  {
                    Header: "Eliminar",
                    accessor: "_id",
                    width: 80,
                    Cell: row => (
                      <div className="delete">
                        <span
                          onClick={() => {
                            if (
                              window.confirm(
                                "¿Estás seguro que quieres borrar esto?"
                              )
                            ) {
                              this.deleteUser(row.value);
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash-alt" />
                        </span>
                      </div>
                    )
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
            {this.state.selectBloque.length > 0 ? (
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col">
                    {" "}
                    <span>
                      Has seleccionado {this.state.selectBloque.length}{" "}
                      {this.state.selectBloque.length < 2
                        ? "usuario"
                        : "usuarios"}
                      .
                    </span>
                  </div>
                  <div className="col-1">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres activar estos usuarios?"
                          )
                        ) {
                          this.state.selectBloque.forEach(function(
                            element
                          ) {
                            request(
                              constantes("URL_API") + "/users/" + element,
                              {
                                method: "PUT",
                                body: { status: true }
                              }
                            );
                          });
                        }
                        this.setState({ selectBloque: [] });
                        this.componentDidMount();
                      }}
                    >
                      {" "}
                      <b className="verde">Activar</b>
                    </span>
                  </div>
                  <div className="col-1">
                    {" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres dejar inactivos estos usuarios?"
                          )
                        ) {
                          this.state.selectBloque.forEach(function(
                            element
                          ) {
                            request(
                              constantes("URL_API") + "/users/" + element,
                              {
                                method: "PUT",
                                body: { status: false }
                              }
                            );
                          });
                        }
                        this.setState({ selectBloque: [] });
                        this.componentDidMount();
                      }}
                    >
                      {" "}
                      <b className="rojo">Desactivar</b>
                    </span>
                  </div>
                  <div className="col-2 text-center">
                    {" "}
                    <span
                      onClick={() => {
                        if (
                          window.confirm(
                            "¿Estás seguro que quieres borrar estos usuarios?"
                          )
                        ) {
                          this.state.selectBloque.forEach(element =>
                            this.deleteUser(element)
                          );
                        }
                        this.setState({ selectBloque: [] });
                      }}
                    >
                      {" "}
                      <i className="fas fa-trash-alt" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="col-3">
              <Button
                className="btn-gradient-style"
                onClick={() => {
                  this.toggle();
                }}
              >
                Añadir Usuario
              </Button>
            </div>
            {/* <MassiveUsers usuarios={this.state.usuarios} reassignCourses={this.reassignCourses} fatherDidMount={this.componentDidMount} canales={this.state.canales} roles={this.state.roles} idRol={this.state.idRol} stores={this.state.stores}/> */}
          </div>
        </div>

        {/* modal para add/edicion  */}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className + "marcomodal"}
        >
          <ModalBody className="modal-registry shadow">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-5">
                    <Label for="UserName">Nombre de Usuario</Label>
                    <Input
                      type="text"
                      name="username"
                      id="userName"
                      placeholder="Nombre de usuario"
                      value={this.state.newUsername}
                      onChange={this.handleNewUserName}
                      className="rounded_cont shadow"
                    />
                    {this.state.unidadUsername !== this.state.usuarios3 &&
                    this.state.newUsername !== this.state.preUsername ? (
                      <div className="row">
                        <div className="col">
                          <Alert color="danger">
                            El nombre de usuario ya está en uso.
                          </Alert>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col">
                    <Label for="rol">Rol</Label>
                    <Input
                      type="select"
                      name="select"
                      id="rol"
                      value={this.state.newRole}
                      onChange={this.handleNewrole}
                      className="rounded_cont shadow"
                    >
                      <option value="" key="none">
                        Ninguno
                      </option>
                      {this.printRoles()}
                    </Input>
                  </div>
                  <div className="col">
                    <Label for="rol">Canal</Label>
                    <Input
                      type="select"
                      name="select"
                      id="rol"
                      value={this.state.newChanel}
                      onChange={this.handleNewchanel}
                      className="rounded_cont shadow"
                    >
                      <option value="" key="none">
                        Ninguno
                      </option>
                      {this.printCanales()}
                    </Input>
                  </div>
                  <div className="col">
                    <Label for="store">Store</Label>
                    <Input
                      type="select"
                      name="select"
                      id="store"
                      value={this.state.newStore}
                      onChange={this.handleNewstore}
                      className="rounded_cont shadow"
                    >
                      <option value="" key="none">
                        Ninguna
                      </option>
                      {this.printStores()}
                    </Input>
                    {/* <Select
                        isMulti
                        options={this.state.stores.map((stores) => 
                          { return {value: stores._id, label: stores.name}}
                       )}
                        className="form-control rounded-select rounded_cont shadow"
                        onChange={this.handleNewstore}
                        value={this.state.newStore}
                        placeholder={"Rol"}
                    /> */}
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      this.state.newRole === "5bd8337c3d34032797d76015"
                        ? "area col shadow col-5 "
                        : "area col shadow d-none"
                    }
                  >
                    <h3>Acceso administración</h3>
                    <Row>
                      <div className="area">
                        <label htmlFor="ad_content">Content</label>
                        <input
                          id="ad_content"
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              zonasAdmin: {
                                ...this.state.zonasAdmin,
                                content: !this.state.zonasAdmin.content
                              }
                            })
                          }
                          checked={
                            this.state.zonasAdmin.content === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="area">
                        <label htmlFor="ad_training">Training</label>
                        <input
                          id="ad_training"
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              zonasAdmin: {
                                ...this.state.zonasAdmin,
                                training: !this.state.zonasAdmin.training
                              }
                            })
                          }
                          checked={
                            this.state.zonasAdmin.training === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="area">
                        <label htmlFor="ad_focus">Focus</label>
                        <input
                          id="ad_focus"
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              zonasAdmin: {
                                ...this.state.zonasAdmin,
                                focus: !this.state.zonasAdmin.focus
                              }
                            })
                          }
                          checked={
                            this.state.zonasAdmin.focus === true
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="area">
                        <label htmlFor="ad_smartlearning">
                          SmartLearning
                        </label>
                        <input
                          id="ad_smartlearning"
                          type="checkbox"
                          onChange={() =>
                            this.setState({
                              zonasAdmin: {
                                ...this.state.zonasAdmin,
                                smartlearning: !this.state.zonasAdmin
                                  .smartlearning
                              }
                            })
                          }
                          checked={
                            this.state.zonasAdmin.smartlearning === true
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Row>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="Email"
                      value={this.state.newEmail}
                      onChange={this.handleNewemail}
                      className="rounded_cont shadow"
                    />
                    {this.state.unidadEmail !== this.state.usuarios3 &&
                    this.state.newEmail !== this.state.preEmail ? (
                      <div className="row">
                        <div className="col">
                          <Alert color="danger">
                            El email ya está en uso
                          </Alert>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-4">
                    <Label for="activo">Estado</Label>
                    <Input
                      type="select"
                      name="select"
                      id="activo"
                      value={this.state.newEstado}
                      onChange={value => this.handleNew(value, "newEstado")}
                      className="rounded_cont shadow"
                    >
                      <option value="true">Activo</option>
                      <option value="false">Inactivo</option>
                    </Input>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <Label for="UserNombre">Nombre</Label>
                    <Input
                      type="text"
                      name="username"
                      id="userNombre"
                      placeholder="Nombre"
                      value={this.state.newName}
                      onChange={value => this.handleNew(value, "newName")}
                      className="rounded_cont shadow"
                    />
                  </div>
                  <div className="col">
                    <Label for="UserApellido">Apellidos</Label>
                    <Input
                      type="text"
                      name="userapellido"
                      id="userApellido"
                      placeholder="Apellidos"
                      value={this.state.newApellido}
                      onChange={value =>
                        this.handleNew(value, "newApellido")
                      }
                      className="rounded_cont shadow"
                    />
                  </div>
                  <div className="col-3">
                    <Label for="UserDNI">DNI</Label>
                    <Input
                      type="text"
                      name="userdni"
                      id="userDNI"
                      placeholder="DNI"
                      value={this.state.newDNI}
                      onChange={value => this.handleNew(value, "newDNI")}
                      className="rounded_cont shadow"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Label for="UserCiudad">Ciudad</Label>
                    <Input
                      type="text"
                      name="userciudad"
                      id="userCiudad"
                      placeholder="Ciudad"
                      value={this.state.newCiudad}
                      onChange={value => this.handleNew(value, "newCiudad")}
                      className="rounded_cont shadow"
                    />
                  </div>
                  <div className="col-5">
                    <Label for="UserCP">CP</Label>
                    <Input
                      type="text"
                      name="usercp"
                      id="userCP"
                      placeholder="CP"
                      value={this.state.newCP}
                      onChange={value => this.handleNew(value, "newCP")}
                      className="rounded_cont shadow"
                    />
                  </div>
                </div>
                {this.state.idEditando === "" ? (
                  <div className="row">
                    <div className="col">
                      <Label for="UserPass">Contraseña</Label>
                      <Input
                        type="password"
                        name="userpass"
                        id="userPass"
                        placeholder="Contraseña"
                        onChange={this.handleNewpass}
                        value={this.state.newPass}
                        className="rounded_cont shadow"
                      />
                    </div>
                    <div className="col">
                      <Label for="confirmPass">Confirma contraseña</Label>
                      <Input
                        type="password"
                        name="confirmpass"
                        id="confirmPass"
                        placeholder="Confirma contraseña"
                        onChange={value =>
                          this.handleNew(value, "confirmPass")
                        }
                        value={this.state.confirmPass}
                        className="rounded_cont shadow"
                      />
                    </div>
                    <div className="col-2">
                      <Label for="randomPass">Aleatoria</Label>
                      <Button
                        className="btn-gradient-style"
                        onClick={this.randomPass}
                      >
                        Generar
                      </Button>
                    </div>
                  </div>
                ) : null}
                <h3>Acceso Apps</h3>
                <Row>                  
                  <label htmlFor="ad_training">Training</label>
                  <input
                    id="ad_training"
                    type="checkbox"
                    onChange={() =>
                      this.setState({
                        zonasApp: {
                          ...this.state.zonasApp,
                          training: !this.state.zonasApp.training
                        }
                      })
                    }
                    checked={
                      this.state.zonasApp.training === true ? true : false
                    }
                  />
                  <label htmlFor="ad_farming">Farming</label>
                  <input
                    id="ad_farming"
                    type="checkbox"
                    onChange={() =>
                      this.setState({
                        zonasApp: {
                          ...this.state.zonasApp,
                          farming: !this.state.zonasApp.farming
                        }
                      })
                    }
                    checked={
                      this.state.zonasApp.farming === true ? true : false
                    }
                  />                  
                  <label htmlFor="ad_smartlearning">SmartLearning</label>
                  <input
                    id="ad_smartlearning"
                    type="checkbox"
                    onChange={() =>
                      this.setState({
                        zonasApp: {
                          ...this.state.zonasApp,
                          smartlearning: !this.state.zonasApp.smartlearning
                        }
                      })
                    }
                    checked={
                      this.state.zonasApp.smartlearning === true
                        ? true
                        : false
                    }
                  />
                </Row>
                {this.state.randomGenerado ? (
                  <div className="row">
                    <div className="col">
                      <Alert color="success" toggle={this.ocultaGenPass}>
                        Contraseña generada.
                      </Alert>
                    </div>
                  </div>
                ) : null}
                {this.state.alertaPass ? (
                  <div className="row">
                    <div className="col">
                      <Alert color="danger">
                        ¡Comprueba que la contraseña coincida en ambos
                        campos!
                      </Alert>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="pretty p-switch p-fill">
                  <input
                    name="borrador"
                    id="confirma-mail"
                    onChange={this.handlemandaMail}
                    type="checkbox"
                    checked={this.state.mandamail}
                  />
                  <div className="state">
                    <label htmlFor="confirma-mail">
                      <em>Enviar email de bienvenida al usuario</em>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
  <div className="pretty p-switch p-fill">
    <input
      name="borrador"
      id="autoasignacion"
      onChange={() =>
        this.setState({
          newAutoasignacion: !this.state.newAutoasignacion
        })
      }
      type="checkbox"
      checked={this.state.newAutoasignacion}
    />
    <div className="state">
      <label htmlFor="autoasignacion">
        <em>Autoasignar cursos</em>
      </label>
    </div>
  </div>
</div>
              
            </div>
            

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <Label htmlFor="avatar">Fotografía</Label>
                    <div className="row">
                      <div className="col">
                        <img
                          id="target"
                          src={this.state.photo}
                          alt="Imagen Destacada"
                          className="imageInput"
                        />
                      </div>
                      <div className="col">
                        <FormText color="muted">
                          Elige la imagen principal que quieras utilizar.
                        </FormText>
                        <Input
                          className="inputAvatar"
                          type="file"
                          name="file"
                          id="avatar"
                          accept=".jpg,.png, .gif"
                          onChange={this.storeAvatar}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-gradient-style"
              onClick={
                this.state.idEditando === ""
                  ? this.addUser
                  : () => {
                      this.editUser(this.state.idEditando);
                    }
              }
              disabled={
                this.state.idEditando === "" ? addEnable : editEnable
              }
            >
              {this.state.idEditando === ""
                ? "Añadir Usuario"
                : "Guardar cambios"}
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Descartar
            </Button>
            {/* <Button 
              color="secondary" 
              onClick={()=>this.reassignCourses(this.state.idEditando)}
            >
              Prueba Cursos
            </Button> */}
          </ModalFooter>
        </Modal>

        {/* MODAL PARA PASSWORD */}
        <Modal
          isOpen={this.state.modalPass}
          toggle={this.togglePass}
          className={this.props.className + "marcomodal"}
        >
          <ModalBody className="modal-registry">
            <Form className="row">
              <Alert
                color="danger"
                className={
                  this.state.alertaPass === true
                    ? "col-12"
                    : "d-none col-12"
                }
              >
                {" "}
                {this.state.confirmPass === ""
                  ? "Introduzca nueva contraseña"
                  : "¡Las contraseñas no coinciden!"}
              </Alert>
              <Alert
                color="success"
                className={
                  this.state.randomGenerado === true ? "col-12" : "d-none"
                }
                toggle={this.ocultaGenPass}
              >
                {" "}
                Contraseña generada.{" "}
              </Alert>
              <FormGroup className="col-5">
                <Label for="UserPass">
                  Nueva contraseña para {this.state.newEmail}
                </Label>
                <Input
                  type="password"
                  name="userpass"
                  id="edituserPass"
                  placeholder="Contraseña"
                  onChange={this.handleNewpass}
                  defaultValue={this.state.newPass}
                  className="rounded_cont shadow"
                />
              </FormGroup>
              <FormGroup className="col-5">
                <Label for="UserPass">
                  Confirma contraseña para {this.state.newEmail}
                </Label>
                <Input
                  type="password"
                  name="confirmpass"
                  id="editconfirmPass"
                  placeholder="Confirma contraseña"
                  onChange={value => this.handleNew(value, "confirmPass")}
                  defaultValue={this.state.confirmPass}
                  className="rounded_cont shadow"
                />
              </FormGroup>
              <div className="col-2">
                <Label for="randomPass">Aleatoria</Label>
                <Button color="primary" onClick={this.randomPass}>
                  Generar
                </Button>
              </div>
              <FormGroup check className="col-12">
                <Input
                  type="checkbox"
                  onChange={this.handlemandaMail}
                  checked={this.state.mandamail}
                />
                <Label check>
                  ¿Quiere enviar un email informando sobre la nueva
                  contraseña?
                </Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.editPass(this.state.idEditando);
              }}
            >
              Guardar cambios
            </Button>
            <Button color="secondary" onClick={this.togglePass}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Users;