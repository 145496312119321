import React, {Component} from 'react';
import { Button,Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import ReactTable from "react-table";
import Loading from '../../components/Loading';
import request from '../../utils/request';
import './styles.css';
import constantes from '../../utils/constantes';
import MasivoPreguntas from '../../components/MasivoPreguntas';



class Questions extends Component {
  constructor(props){
    super(props);
    this.state = {
      editId: '',
      idEditando:'',
      loading: false,
      modal: false,
      modalEditQuestion: false, 
      newCantidad: 3,
      newStatement: '',
      newRespuestas: [],
      newCorrecta: '',
      newQuestionName: '',
      photo: '/img/userplaceholder.jpg',
      questions: [],
      selectBloque: [],

    };    
    
  }
  limpiezaEstados = async ()=>{
    await this.setState({         
      newQuestionName: '',
      newCorrecta: '',
      newCantidad: 3,
      newRespuestas:[], 
      newStatement:'',
      editId: '',
      idEditando:'',
      loading: false,
    })
    this.gestionaRecords()
  }
  handleNewRespuestas = (value, position) =>{
    let array = this.state.newRespuestas;
    let objeto = new Object();
    objeto.texto = value.target.value;
    objeto.value = "false";
    array[position]=objeto;
    this.setState({
        newRespuestas: array
    }); 
    
  }
  filtraQuestions = (value) => {
    if (value.target.value !=="" || value.target.value!==undefined) {
      let dataFiltro = this.state.questions2.filter(array => array.text.includes(value.target.value))
      this.setState({questions: dataFiltro})      
    } else {
      this.setState({questions: this.state.questions2})
      }
    }  
  setLoading = (loading) =>{
    this.setState({
      loading
    });
  }
  actualizaQuestions = (id) =>{
    const questionsMod= [...this.state.questions]; //copia el estado
    questionsMod.find(e => e._id===id).status = !(questionsMod.find(e => e._id===id).status); 
    this.setState({questions : questionsMod});
  }
  toggle = (id = "") => {
    //si está abierto lo cerramos
    if (this.state.modal===true)
    {this.limpiezaEstados();
      this.setState({
        modal: !this.state.modal
        
      });} else {
        //compruebo que tenga ID válido
        if (id!==""){
          //Si tiene ID, cargamos la data del ID y abrimos.
          this.setState({loading:true})
            request(constantes("URL_API") + "/questions/"+id, { method: "GET" })    
            .then((questions) => this.setState({
              editQuestionData: questions,
              newStatement: questions.text,
              newCantidad: questions.answers.length,
              newCorrecta: questions.answers.map(function(e) { return e.value; }).indexOf('true')+1,
              idEditando: id,              
              newRespuestas: questions.answers,
              modal: !this.state.modal,
              loading:false,
              
              }));        
        } else {
          //Si no tiene ID, abrimos el modal
          this.setState({
            modal: !this.state.modal          
          });
        }
      }
  }
 
  gestionaBloque = (id) => {
    let array = this.state.selectBloque;
    if (array.includes(id))
    {
      //eliminar elemento del array
      var index = array.indexOf(id);
      if (index > -1) {
      array.splice(index, 1);
    }
      
    } else {
      //Añadir al array
      array.push(id)
    };
    this.setState({selectBloque: array});
  }

  editQuestion=(id)=>{        
    let trueRespuestas = this.state.newRespuestas.map((e) =>{
          e.value="false"
          return e;
        }
      )   
    let num = this.state.newCorrecta-1   
      trueRespuestas[num].value = "true";
      console.log(trueRespuestas[num]) 
      console.log(this.state.newRespuestas) 
      this.setState({newRespuestas: trueRespuestas})
    
      let body = {
        text: this.state.newStatement,
        answers: this.state.newCantidad,
        type: "radio",
        answers:this.state.newRespuestas,
        area_admin: localStorage.getItem('admin')
    }
    request(constantes("URL_API") + "/questions/"+id, {
      method: "PUT",
      body: body,
     }).then( response => {                  
                    this.limpiezaEstados();
                    this.componentDidMount();
                }).then(() => this.setState({
                  modal: !this.state.modal
                })).catch( err => console.log( err) )
    }    

  addQuestion = () =>{  
      let trueRespuestas = this.state.newRespuestas;
      trueRespuestas[this.state.newCorrecta-1].value = "true";
      this.setState({newRespuestas: trueRespuestas})
    
      let body = {
        text: this.state.newStatement,
        answers: this.state.newCantidad,
        type: "radio",
        answers:this.state.newRespuestas,
        area_admin: localStorage.getItem('admin')
    }
    //console.log(body)
    request(constantes("URL_API") + "/questions", {
        method: "POST",
        body: body,
        

    }).then( response => {               
        this.limpiezaEstados();
        this.componentDidMount();
    }).then(() => this.setState({
      modal: !this.state.modal
    }))    
   
    }
  deleteQuestion = (id)=> {
    request(constantes("URL_API") + "/questions/"+id, { method: "DELETE" })
    .then((response) => this.componentDidMount()).catch( err => console.log( err) );
  }
  handleNewRespuestas = (value, position) =>{
    let array = this.state.newRespuestas;
    let objeto = new Object();
    objeto.texto = value.target.value;
    objeto.value = "false";
    array[position]=objeto;
    this.setState({
        newRespuestas: array
    }); 
    
  }
  handleNew = (value, concepto) => {
    let estado = "new"+concepto;
    this.setState({
        [estado]: value.target.value
    });
  }
  handlenewCantidad = (value) =>{
    this.setState({
        newCantidad: value.target.value
    }, ()=>{this.gestionaRecords()});    
    
  }

  printReminders = () => {          
          let inputRespuestas = []            
          for (let paso = 0; paso <this.state.newRespuestas.length; paso++) {            
            inputRespuestas.push(
              <div className="row" key={paso}>
              <div className="col">
                <FormGroup>
                  <Label for={"Respuesta "+(paso+1)}>Respuesta {paso+1}</Label>
                  <Input type="text" 
                        name="name" 
                        id={"Respuesta "+(paso+1)} 
                        key={paso} 
                        placeholder={"Respuesta "+(paso+1)} 
                        onChange={ (value) => this.handleNewRespuestas(value, paso)}
                        value={this.state.newRespuestas[paso].texto} 
                        />                 
                </FormGroup>
                </div>                                    
              </div>
            );            
          };          
            
          return inputRespuestas;          
        }      
  
  gestionaRecords = () =>{
    if (this.state.newCantidad>this.state.newRespuestas.length)
          { let diff = this.state.newCantidad - this.state.newRespuestas.length
            for (let paso = 0; paso < diff; paso++){
              let recor2 = this.state.newRespuestas;
              recor2.push({texto:'',value:"false"});
              this.setState({newRespuestas: recor2});
            } 
          } else if (this.state.newCantidad<this.state.newRespuestas.length && this.state.newCantidad>=0){
            
            let diff = this.state.newRespuestas.length - this.state.newCantidad
            for (let paso = 0; paso < diff; paso++){                
              let recor3 = this.state.newRespuestas;
              recor3.pop();
              this.setState({newRespuestas: recor3});
            } 
          } 
  }

  componentDidMount = () => {
    this.setState({loading:true})
    var consulta = "/questions";
    request(constantes("URL_API") + consulta, { method: "GET" })
    .then((questions) => this.setState({
      questions: questions.filter(e => e.area_admin === localStorage.getItem('admin')),
      questions2: questions.filter(e => e.area_admin === localStorage.getItem('admin')),      
    },()=>{this.setState({loading:false})}));
    this.gestionaRecords();
   
    
  }
  

  render () {
      const isDisabled = 
      this.state.newStatement ===""|| 
      this.state.newCantidad<1 || 
      this.state.newCantidad==="" ||
      this.state.newCorrecta<1 ||
      this.state.newCorrecta==="" || 
      (this.state.newRespuestas.length>=1?this.state.newRespuestas[0].texto==="":null)
      ?true:false;
      return(

        <div className = "main_content listado">
          <div><h3>Preguntas</h3></div>
          <div className="rounded_cont contents shadow">
              <div className="row">
              
                <div className="col">
                <Loading loading={this.state.loading}/>
                <input type="text" placeholder="Nombre de la Pregunta" onChange={this.filtraQuestions} className="form-control list-search"/>
                    <ReactTable
                      data={this.state.questions}                      
                      columns={[
                        {
                          Header: '',                        
                          accessor: "_id",
                          width: 40,
                          Cell: row => (
                          <div className=""><input name={row.value} id={row.value} type="checkbox" onChange={() => this.gestionaBloque(row.value)} checked={this.state.selectBloque.includes(row.value)?true:false}/>
                          <div className="state"></div></div>                     
                            )
                        },                        
                        {
                          Header: "Enunciado",
                          accessor: "text",
                          //width: 120,                          
                          Cell: row => (
                            <div className = "text">
                              { row.value } 
                            </div>
                          )
                        },
                        {
                          Header: "Respuesta A",
                          accessor: "answers",                                                  
                          Cell: row => (
                            <div className = "desc">
                            {  row.value[0]?(row.value[0].value==="true"?<u>{row.value[0].texto}</u>:row.value[0].texto):''  }                              
                             </div>
                           )
                         }
                         ,
                        {
                          Header: "Respuesta B",
                          accessor: "answers",                                                  
                          Cell: row => (
                            <div className = "desc">
                            { row.value[1]?(row.value[1].value==="true"?<u>{row.value[1].texto}</u>:row.value[1].texto):'' }                              
                             </div>
                           )
                         },
                         {
                           Header: "Respuesta C",
                           accessor: "answers",                                                  
                           Cell: row => (
                             <div className = "desc">
                             {  row.value[2]?(row.value[2].value==="true"?<u>{row.value[2].texto}</u>:row.value[2].texto):''  }                              
                              </div>
                            )
                          },                                              
                         {
                          Header: "Editar",
                          accessor: "_id",
                          width: 80,                          
                          Cell: row => (
                            <div className = "edit">
                            <span onClick={() =>{this.toggle(row.value)}}><i className="fas fa-pencil-alt"/></span>
                              
                             </div>
                           )
                         },
                        {
                          Header: "Eliminar",
                          accessor: "_id",
                          width: 80,                          
                          Cell: row => (
                            <div className = "delete">
                              <span onClick={ () => {
                                    if (window.confirm("¿Estás seguro que quieres eliminar esta pregunta?")){
                                      this.deleteQuestion(row.value)
                                    }
                                  }
                                } > <i className="fas fa-trash-alt"/> 
                              </span>
                            </div>
                          )
                        }
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                </div>
                {this.state.selectBloque.length > 0?
              <div className="col-12">
                <div className="row menuBloque">
                  <div className="col-10"> <span >Has seleccionado {this.state.selectBloque.length} {this.state.selectBloque.length<2?"pregunta":"preguntas"}.</span>
                    </div>
                    <div className="col-2 text-center"> <span onClick={ () => {
                                if (window.confirm("¿Estás seguro que quieres eliminar estas preguntas?")){
                                  this.state.selectBloque.forEach((element)=>this.deleteQuestion(element))                                                                    
                                };
                                this.setState({selectBloque:[]});                                
                              }
                            } > <i className="fas fa-trash-alt"/> 
                          </span>
                    </div>
                </div>
              </div>:""}
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Button className="btn-gradient-style" onClick={()=>this.toggle()} >Añadir Pregunta</Button>
            </div>
            <div className="col-3">
              <MasivoPreguntas componentDidMount={this.componentDidMount}/>
            </div>
            <div className="col-3"></div>
            <div className="col-3"></div>
          </div>
          {/* <div className="row">
            <div className = "col">
              <div className = "btns">
                <div className="ml-0 mr-0 pl-0 pr-0 buttons">                                
                <div className = "col-3">
                  <Button color="primary" onClick={()=>this.toggle()} >Añadir Pregunta</Button>
                  </div>  
                </div>                
              </div>
            </div>
          </div> */}

                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + ('marcomodal')}>          
                          <ModalBody className="registroUser">
                            <div className="row">
                              <div className="col">
                              <Form>                               
                                <div className="row">
                                  <div className="col">
                                    <FormGroup>
                                      <Label for="statement">Enunciado</Label>
                                      <Input type="textarea" name="desc" id="statement" placeholder="Enunciado de la pregunta" value={this.state.newStatement} onChange={(value)=>this.handleNew(value, "Statement")}/>
                                    </FormGroup>
                                    </div>                                    
                                  </div> 
                                   
                                    <div className="row">
                                      {/* <div className="col">
                                        <FormGroup>
                                          <Label for="UserName">Cantidad de respuestas</Label>
                                          <Input type="number" name="name" id="cantidad" placeholder="Cantidad de Respuestas" value={this.state.newCantidad} onChange={this.handlenewCantidad}/>
                                        </FormGroup>
                                      </div>                                     */}
                                      <div className="col">
                                        <FormGroup>
                                          <Label for="UserName">Respuesta correcta.</Label>
                                          <Input type="number" name="name" id="repeticiones" placeholder="Respuesta correcta" value={this.state.newCorrecta} onChange={(value)=>this.handleNew(value, "Correcta")}/>
                                        </FormGroup>
                                      </div>                                    
                                    </div>
                                    {this.printReminders()}
                                    
                                  </Form> 
                                </div>  
                              </div>  
                              <div className="row">

                              </div>
                          </ModalBody>
                        <ModalFooter>
                          <Button className="btn-gradient-style" 
                            onClick={this.state.idEditando===''?this.addQuestion:() =>{this.editQuestion(this.state.idEditando)}} 
                            disabled={isDisabled}
                                      >{this.state.idEditando===''?'Añadir pregunta':'Guardar cambios'}</Button>
                          <Button className="btn-gradient-style" onClick={this.toggle}>Descartar</Button>                          
                        </ModalFooter>
                      </Modal>                    
        </div>
      );
  }
}
export default Questions;