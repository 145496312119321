import React, { Component } from "react";
import gql from "../../utils/gql";
import { Button, Row, Col } from "reactstrap";
import request from "../../utils/request";
import constantes from "../../utils/constantes";
import _ from "lodash";
import Select from "react-select";

export default class LoadUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedsolviacanal: [...this.props.cursesolviacanals],
            selectedsolviarol: [...this.props.cursesolviarols],
            selectedstores: [...this.props.cursestores]
        }
    }
  handleChangeCanal = async selectedOption => {
    this.setState({ selectedsolviacanal: selectedOption });
  };

  handleChangeRol = async selectedOption => {
    await this.setState({ selectedsolviarol: selectedOption });
  };

  handleChangeStores = async selectedOption => {
    await this.setState({ selectedstores: selectedOption });
  };

  getUsers = async () => {
    this.props.loading(true);

    const {
      selectedsolviacanal,
      selectedsolviarol,
      selectedstores
    } = this.state;

    const idselectedsolviacanal = JSON.stringify(
      selectedsolviacanal.length > 0
        ? selectedsolviacanal.map(x => x.value)
        : []
    );
    const idsselectedsolviarol = JSON.stringify(
      selectedsolviarol.length > 0 ? selectedsolviarol.map(x => x.value) : []
    );
    const idsselectedstores = JSON.stringify(
      selectedstores.length > 0 ? selectedstores.map(x => x.value) : []
    );

    const query = `
      {
        solviacanals(where: {_id: $idselectedsolviacanal$}){
          _id
          users{
            _id
            email
          }
        }
        solviarols(where: {_id: $idsselectedsolviarol$}){
          _id
          users{
            _id
            email
          }
        }
        stores(where: {_id: $idsselectedstores$}){
          _id
          users{
            _id
            email
          }
        }
      }
    `;

    const response = await request(
      gql(
        query,
        { idselectedsolviacanal, idsselectedsolviarol, idsselectedstores },
        constantes("URL_API")
      )
    );

    const reponseCanals = _.flatMap(response.data.solviacanals, x => x.users);
    const reponseRols = _.flatMap(response.data.solviarols, x => x.users);
    const reponseStores = _.flatMap(response.data.stores, x => x.users);
    const cuttieUsers = [...reponseCanals, ...reponseRols, ...reponseStores];
    const users = _.uniqBy(cuttieUsers, "_id").map(x => ({
      value: x._id,
      label: x.email
    }));

    this.props.usuariosSelect({ users, usuariosSelect: users, loading: false });
  };

  render = () => {
    return (
      <div>
        <Row>
          <Col>
            <Select
              isMulti
              options={this.props.solviacanals}
              className="form-control rounded-select rounded_cont shadow"
              onChange={this.handleChangeCanal}
              value={this.state.selectedsolviacanal}
              placeholder={"Canal"}
            />
          </Col>
          <Col>
            <Select
              isMulti
              options={this.props.solviarols}
              className="form-control rounded-select rounded_cont shadow"
              onChange={this.handleChangeRol}
              value={this.state.selectedsolviarol}
              placeholder={"Rol"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              isMulti
              options={this.props.stores}
              className="form-control rounded-select rounded_cont shadow"
              onChange={this.handleChangeStores}
              value={this.state.selectedstores}
              placeholder={"Store"}
            />
          </Col>
          <Col>
            <Button className="btn-gradient-style" onClick={this.getUsers}>
              Añadir Usuarios
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
}